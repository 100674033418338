  /* 404 page container */
  .notFoundContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
  }
  .notFoundMessageBox {
    position: fixed;
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 45%;
  }

  /* Left container */
  .leftContainer {
    width: 50%;
    height: 100%;
    float: left;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: start;
    transition-duration: 0.65s, 1s;
    position: fixed;
  }
  .leftContainer.mobile {
    width: 100%;
    height: auto;
    min-height: 450px;
    float: none;
    position: relative;
    scroll-snap-align: start;
  }
  @media (max-height: 650px) and (min-width: 769px) {
    .leftContainer {
      overflow: scroll;
    }
    .footer {
      opacity: 0;
    }
  }
  @media (max-height: 520px) {
    .leftContainer.mobile.small {
      height: 100vh;
    }
  }
  @media (min-height: 521px) {
    .leftContainer.mobile.small {
      height: 60vh;
    }
  }
  .leftContainer.mobile.large {
    height: 100vh;
  }

  .menu {
    padding-left: 5%;
    padding-top: 6%;
    height: fit-content;
  }
  .menu.mobile {
    padding-top: 0%;
  }

  .messageBox {
    padding-left: 6%;
    width: 80%;
  }
  .messageBox.mobile {
    width: 90%;
  }

  .footer {
    transition-duration: 0.5s, 1s;
    padding-left: 3%;
    position: fixed;
    bottom: 0;
  }
  .footer.mobile {
    padding: 5%;
    position: relative;
    top: 0;
  }
  .logoImage {
    height: fit-content;
    width: var(--image-size-logo);
    object-fit: scale-down;
  }
  .instagramLogoImage {
    height: 0.9rem;
    width: 0.9rem;
    vertical-align: middle;
  }

  /* Right container */
  .rightContainer {
    width: 50%;
    height: 100%;
    float: right;
    max-height: 200vh;
    min-height: 100vh;
    padding-bottom: 50px;
  }
  .rightContainer.mobile {
    width: 100%;
    height: fit-content;
    max-height: none;
    min-height: none;
    float: none;
    display: flex;
    position: sticky;
  }

  .productLogo {
    height: 70px;
    width: 100%;
    object-fit: scale-down;
  } 
  .productImage {
    width: 100%;
    height: 30vh;
    min-height: 300px;
    align-content: center;
    justify-content: center;
    background-color: transparent;
    object-fit: contain;
    overflow: hidden;
  }
  .gridBox {
    padding: 10%;
  }

  .catalogFooter {
    height: 56px;
    width: 50%;
    background-color: black;
    position: fixed;
    justify-content: center;
    display: flex;
    bottom: 0;
    cursor: pointer;
  }
  .catalogFooter.mobile {
    width: 100%;
  }
  @media (hover: hover) and (pointer: fine) {
    .catalogFooter:hover {
      background-color: rgb(30, 30, 30);
    }
  }
  .catalogFooterImage {
    margin: auto;
    height: 45%;
    object-fit: scale-down;
  }

  /* Menu button */
  .defaultButton  {
    border: none;
    background-color: transparent;
    font-size: var(--font-size-sm);
    color: rgba(0, 0, 0, 0.4);
    padding-right: 4%;
    cursor: pointer;
  }
  .defaultButton.active  {
    border: none;
    background-color: transparent;
    font-size: var(--font-size-sm);
    color: black;
    padding-right: 4%;
    pointer-events: none;
  }
  .plainButton {
    border: none;
    background-color: transparent;
    font-size: var(--font-size-sm);
    color: rgba(255, 255, 255, 0.4);
    padding-right: 4%;
    cursor: pointer;
  }
  .plainButton.active {
    border: none;
    background-color: transparent;
    font-size: var(--font-size-sm);
    color: white;
    padding-right: 4%;
    pointer-events: none;
  }

  .defaultButton.mobile {
    font-size: var(--font-size-lg-mobile);
  }
  .defaultButton.active.mobile {
    font-size: var(--font-size-lg-mobile);
  }
  .plainButton.mobile {
    font-size: var(--font-size-lg-mobile);
  }
  .plainButton.active.mobile {
    font-size: var(--font-size-lg-mobile);
  }
  
  /* Text */
  :root {
    --font-size-lg: clamp(1.2rem, 3vw, 3rem);
    --font-size-sm: clamp(0.5rem, 1.6vw, 1.5rem);
    --font-size-xsm: clamp(0.7rem, 1vw, 1rem);
    --image-size-logo: clamp(15rem, 5vw, 30rem);

    --font-size-lg-mobile: clamp(1rem, 2vw, 4rem);
    --font-size-sm-mobile: clamp(1rem, 2vw, 2rem);
    --logo-size-mobile: clamp(0.5rem, 3vw, 3rem);
  }

  p.large {
    font-size: var(--font-size-lg);
  }
  p.small {
    font-size: var(--font-size-sm);
    line-height: 2.2rem;
  }
  p.xsmall {
    font-size: var(--font-size-xsm);
    height: 20px;
    text-align: center;
  }

  p.white {
    color: white;
  }
  p.black {
    color: black;
  }
  p.white.mobile {
    line-height: 1.5rem;
    font-size: var(--font-size-sm-mobile);
    position: relative;
    overflow: hidden;
  }
  p.black.mobile {
    color: black;
    line-height: 1.5rem;
    position: relative;
    overflow: hidden;
  }

  p.logo {
    font-size: var(--font-size-xsm);
    line-height: 1rem;
  }

  p.anim {
    animation: fadein 0.5s ease-in-out;
  }

  p.notFoundMessage {
    font-size: 6vw;
    text-align: center;
    font-weight: bold;
    color: black;
    bottom: 0;
  }

  a {
    text-decoration: none;
  }

  a.black {
    color: black;
  }

  a.white {
    color: white;
  }

  /* Others */
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-use-select: none;
    user-select: none;
  }

  body::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;

    content: url("../img/background/vision.png") 
    url("../img/background/philosophy.png")
    url("../img/background/message.png") 
    url("../img/background/design_of_water.png") 
    url("../img/logo/insta_black.png") 
    url("../img/logo/insta_white.png");
  }

  html {
    overflow: hidden;
    height: 100%;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @keyframes fadein{
    0% {
    opacity: 0;
    transform: translateY(20px);
    }
    100% {
    opacity: 1;
    transform: none;
    }
  }

  * {
    -webkit-text-size-adjust:none;
  }

  span {
    font-family: "Noto Sans KR";
  }