@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 100;
    src: url("./NotoSansKR-Light.eot") format("eot");
  }
  
  @font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: normal;
    src: url("./NotoSansKR-Regular.eot") format("eot");
  }
  
  @font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    src: url("./NotoSansKR-Medium.eot") format("eot");
  }
  
  @font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: bold;
    src: url("./NotoSansKR-Bold.eot") format("eot");
  }
  
  @font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: lighter;
    src: url("./NotoSansKR-Thin.eot") format("eot");
  }